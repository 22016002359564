import React, { Component } from 'react';
import Modal from '../../modal/components/Modal'

import GutterForm from '../../gutter/containers/MasterForm'

class Dashboard extends Component{
  
  constructor(){
    super();
    
    this.closeModal = this.closeModal.bind(this);
  }


  componentDidMount(){
    
  }

  closeModal(){
    $('.close').click();
  }

  render(){

    return(
      <div className="our-services">
        <div className="container">
          <h2 className="heading">EMPOWERING EXTERIORS</h2>
          <div className="row">          

            <div className="col-md-3 col-sm-6 col-xs-12 ">
              <div className="ser-block ser-housekeep hover-style">
                <a href="/roofing">
                  <img src="./assets/roofing_installation.jpg" height="200"/>
                  <span className="inner-title">ROOFING</span>
                </a>
              </div>
            </div>

            <div className="col-md-3 col-sm-6 col-xs-12">
          <div className="ser-block ser-gutter hover-style">
                <a href="/solar">
                  <img src="./assets/solar_1.jpg" height="200"/>
                  <span className="inner-title">SOLAR</span>
                </a>
              </div>
            </div>
            {/* <div className="col-md-4 col-sm-6 col-xs-12">
              <div className="ser-block ser-pressure hover-style">
                <a href="/services/Solar_Roof">
                  <img src="./assets/Solar_Roof.jpg" height="200"/>
                  <span className="inner-title">Solar Roofing</span>
                </a>
              </div>
            </div> */}
            <div className="col-md-3 col-sm-6 col-xs-12">
              <div className="ser-block ser-window hover-style">
                <a href="/services/siding_installation">
                  <img src="./assets/hardie_5.jpg" height="200" />
                  <span className="inner-title">SIDING</span>
                </a>
              </div>
            </div>

            <div className="col-md-3 col-sm-6 col-xs-12">
              <div className="ser-block ser-pressure hover-style">
                <a href="/services/install_windows">
                  <img src="./assets/Windows_Installation.jpg" height="200"/>
                  <span className="inner-title">WINDOWS</span>
                </a>
              </div>
            </div>

          {/* <div className="col-md-4 col-sm-6 col-xs-12">
          <div className="ser-block ser-gutter hover-style">
                <a href="/services/gutter_cleaning">
                  <img src="./assets/Gutter_Cleaning_photo_3.jpg" height="200 wid"/>
                  <span className="inner-title">GUTTER CLEANING</span>
                </a>
              </div>
            </div> */}

            {/* <div className="col-md-4 col-sm-6 col-xs-12">
              <div className="ser-block ser-home hover-style">
              <a class="exterior-door" data-target="#myModal" data-toggle="modal" href="javascript:void(0)">
                  <img src="./assets/doors.jpeg" height="200"/>
                  <span className="inner-title">Exterior Doors</span>
                </a>
              </div>
            </div> */}
            <div>
              
            {/* <div className="col-md-4 col-sm-6 col-xs-12">
              <div className="ser-block ser-solar hover-style">
              <a class="gutter-installation" data-target="#myModal" data-toggle="modal" href="javascript:void(0)">
                  <img src="./assets/GutterInstallation_Image.jpg" height="200"/>
                  <span className="inner-title">Gutters</span>
                </a>
              </div>
            </div> */}

            <Modal closeModal={this.closeModal} modalId="edit" title='Select house size' action="edit">
              <GutterForm closeModal={this.closeModal} />
            </Modal>
                  
          </div>
          </div>
        </div>
      </div> 
    );
  }

}

export default Dashboard;
